body {
  padding: 0;
  margin: 0;
  /* font-family: "Ubuntu", sans-serif; */
  /* font-family: "Nanum Pen Script", cursive; */
  /* font-family: "Gochi Hand", cursive; */
  font-family: "VT323", monospace;
  /* font-family: "Source Code Pro", monospace; */
  background-color: #ffffff;

  /* font-family: "Gloria Hallelujah", cursive; */
  /* font-family: "Gochi Hand", cursive; */
  /* font-family: "Roboto", sans-serif; */
  /* font-family: "Comic Neue", cursive; */
  /* background-color: #7067f8; */
  /* background: url("../src/Components/Image/Bg/gradient1.png"); */
  /* background-size: cover; */
  /* background-repeat: no-repeat; */
}
html {
  scroll-behavior: smooth;
}
.marginComponent {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.padding {
  padding: 3rem;
}
.marginElement {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.marginBottom {
  margin-bottom: 5rem;
}

.title {
  font-size: 55px;
  color: #000000;
  font-weight: 800;
  line-height: 1.2;
  text-align: center;
}
.title-info {
  /* color: #000; */
  color: #000000;
  font-size: 40px;
  /* text-transform: uppercase; */
  line-height: 0.95;
  font-weight: 700;
  text-align: center;
}

.description {
  font-size: 23px;
  font-weight: 600;
  color: rgb(0, 0, 0);
  text-align: center;
}

.dark-background {
  background-color: #ffffff;
}

/* @media (max-width: 600px) {
  .nav-link {
    padding: 10px 10px;
  }
  .title {
    font-size: 38px;
  } */
/* } */
@media (max-width: 500px) {
  .title {
    font-size: 40px;
  }
  .title-main {
    font-size: 40px;
  }
  .title-info {
    font-size: 38px;
  }
  .description {
    font-size: 15px;
  }
}
/* @media (max-width: 300px) {
  .title {
    font-size: 20px;
  }
  .title-info {
    font-size: 15px;
  }
  .description {
    font-size: 12px;
  }
} */
